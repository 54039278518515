import * as React from "react";
import Navbar from "../sections/Navbar";
import Seo from "../sections/seo";
import HotelsSection from "../sections/Hotels";
import { Faq } from "../sections/Faq";
import SocialMedia from "../sections/SocialMedia";
import WaitingListProvider from "contexts/WaitingListProvider";
import HotelModal from "../components/modal/HotelModal";
import BusinessModal from "components/modal/BusinessModal";
import WaitingListModal from "components/modal/WaitingListModal";
import BusinessProvider from "../contexts/BusinessProvider";
import HotelProvider from "../contexts/HotelProvider";
import Footer from "../sections/Footer";
import CookieBanner from "components/CookieBanner";

export default function Hotels() {
  return (
    <div>
      <Seo />
      <SocialMedia />
      <HotelProvider>
        <BusinessProvider>
          <WaitingListProvider>
            <WaitingListModal />
            <BusinessModal />
            <HotelModal />
            <Navbar />
            <HotelsSection />
          </WaitingListProvider>
        </BusinessProvider>
      </HotelProvider>
      <Footer />
      <CookieBanner />
    </div>
  );
}
