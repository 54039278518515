import * as React from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Typography from "components/Typography";

const faqs = [
  {
    question: "Why work in hotels? What do I get out of it?",
    answer:
      "Hotels are inspiring places where you can easily balance work and leisure. Because there is a sense of service you don’t get anywhere else. Because it’s home away from home.",
  },
  {
    question: "How do I know if I have the profile of an ooofer?",
    answer:
      "If you regularly work from home, in cafes, or in coworking spaces. If you want to fall asleep in one place and wake up in another, if you want to mix work and pleasure, then you are an ooofer at heart.",
  },
  {
    question: "Who will I meet if I become an ooofer?",
    answer:
      "Freelancers, consultants, entrepreneurs, startuppers and creatives. People like you who want to totally embrace the idea of remote work and take it to another level.",
  },
  {
    question: "What's the next ooofer destination?",
    answer:
      "Ooofer is conquering Europe as we speak. After Belgium, France and Germany, the next locations will be in the Netherlands, Luxembourg and Switzerland but also in Portugal, Spain and Italy. Looking forward to seeing you there.",
  },
  {
    question: "How can I become an ooofer ambassador and what does it mean?",
    answer:
      "Being an ooofer ambassador means that you are building the service with us. Let us know what you would like to see on ooofer in terms of packages, locations, or general features. In return we garantee you a special treatment on the ooofer platform ! To become an ooofer ambassador you can get in touch with us at info[at]ooofer.com and we will get back to you with further instructions.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Faq = () => {
  const h2 = "Questions? Answered.";
  return (
    <div className="px-4 py-10 sm:px-0">
      <div className="max-w-4xl mx-auto">
        <Typography variant="h2">{h2}</Typography>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="flex items-center ml-6 h-7">
                        <ChevronDownIcon
                          className={classNames(
                            open ? "-rotate-180" : "rotate-0",
                            "h-6 w-6 transform"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0"
                  >
                    <Disclosure.Panel as="dd" className="pr-12 mt-2" static>
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};
