import React from "react";
import Typography from "components/Typography";
import AppButton from "../components/AppButton";
import HotelsList from "../components/HotelsList/HotelsList";
import {hotels} from "../data/hotels";

function HotelsSection(props) {
  return (
    <div className="px-5 py-8">
      <div className="mx-auto text-center max-w-7xl">
        <div className="px-4 mx-auto mb-6 max-w-7xl sm:px-0">
          <div className="max-w-3xl m-auto">
          <Typography variant="h2" className="my-16 ">
            Lifestyle hotels fit for work and leisure, near you.
          </Typography>
          </div>
        </div>
        <div className="relative flex items-center justify-center">
          <HotelsList hotels={hotels} />
        </div>
        <div className="max-w-3xl py-16 m-auto">
          <Typography variant="h2" className="mb-10 ">
            Want to see more ?
          </Typography>
          <AppButton
              as="a"
              to="/redirect-to-store"
              size="base"
              background="black"
              title="Download the app"
            />
        </div>
      </div>
    </div>
  );
}

export default HotelsSection;
